import React from "react";
import Joi from "joi";
import useInterval from "use-interval";
import usePrevious from "use-previous";
import { Authentication, ErrorHandler, Notice, Role, Waiting } from "../Store";
import { Spinner } from "reactstrap";
import NoticeArea from "../NoticeArea";
import { HandledError } from "../Store/ErrorHandler";
import Student from "./Student";
import Instructor from "./Instructor";

class TeamError extends HandledError {
    constructor(message, validation = null) {
        super("TeamsError", message, null, validation);
    }
}

/** 
 * @typedef TeamModel
 * @property {String} bb
 * @property {String} created
 * @property {String} team
 * @property {String} praise
 * @property {String} verified
 * @property {String} link
 * @property {String} members
 */

const teamSchema = Joi.object({
    bb: Joi.string().required(),
    created: Joi.string().isoDate().allow(null).optional(),
    team: Joi.string().allow(null).optional(),
    praise: Joi.string().required(),
    verified: Joi.string().isoDate().allow(null).optional(),
    gone: Joi.boolean().required(),
    link: Joi.string().uri().allow(null).optional(),
    members: Joi.string().allow(null).optional(),
    courseType: Joi.string().allow(null).optional(),
    exists: Joi.string().isoDate().allow(null).optional()
});

/**
 * Validate the team object
 *
 * @param {*} team
 * @return {TeamModel} 
 */
const validateTeam = team => {
    const result = teamSchema.validate(team);
    if (Boolean(result.error)) {
        throw new TeamError("Malformed Response", result);
    }
    return team;
}

const ViewContainer = () => {

    const { properties, fetchApi, hasRole } = React.useContext(Authentication);
    const { handleError } = React.useContext(ErrorHandler);
    const { info, danger } = React.useContext(Notice);
    const { waitFor } = React.useContext(Waiting);

    const [ready, setReady] = React.useState(false);
    const [gone, setGone] = React.useState(false);

    /** @type {[TeamModel, React.Dispatch<TeamModel>]} */
    const [team, setTeam] = React.useState(null);

    const prevTeam = usePrevious(team);

    const createTeam = React.useCallback(() => {
        const stopWait = waitFor("CREATE_TEAM");
        fetchApi("/teams", { method: "POST" })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new TeamError("Could not contact Teams API");
            })
            .then(validateTeam)
            .then(setTeam)
            .then(() => {
                info("Team creation request has been accepted, please wait for your team to be created.")
            })
            .catch(handleError)
            .finally(stopWait)
    }, [fetchApi, handleError, info, waitFor]);

    const getTeam = React.useCallback(() => {
        const stopWait = waitFor("GET_TEAM");
        fetchApi("/teams", { method: "GET" })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateTeam);
                }
                if (response.status === 404) {
                    return null;
                }
                throw new TeamError("Could not contact Teams Integration API");
            })
            .then(setTeam)
            .then(() => setReady(true))
            .catch(handleError)
            .finally(stopWait)
    }, [fetchApi, handleError, setReady, waitFor]);

    const syncTeam = React.useCallback(() => {
        const stopWait = waitFor("GET_TEAM");
        fetchApi("/teams/sync", { method: "POST" })
            .then(response => {
                if (response.ok) {
                    return response.json()
                        .then(validateTeam);
                }
                if (response.status === 429) {
                    throw new TeamError("Blackboard API calls limit reached");
                }
                throw new TeamError("Could not contact Teams Integration API");
            })
            .then(setTeam)
            .then(() => setReady(true))
            .catch(handleError)
            .finally(stopWait)
    }, [fetchApi, handleError, setReady, waitFor]);

    const forgetTeam = React.useCallback(() => {
        const stopWait = waitFor("GET_TEAM");
        fetchApi("/teams/forget", { method: "POST" })
            .then(response => {
                if (response.ok) {
                    setGone(true);
                    return null;
                }
                throw new TeamError("Could not contact Teams Integration API");
            })
            .then(setTeam)
            .then(() => setReady(true))
            .catch(handleError)
            .finally(stopWait)
    }, [fetchApi, handleError, setReady, waitFor]);

    const joinTeam = React.useCallback(() => {
        const stopWait = waitFor("GET_TEAM");
        fetchApi("/teams/join", { method: "POST" })
            .then(response => {
                if (response.ok) {
                    return null;
                }
                throw new TeamError("Could not contact Teams Integration API");
            })
            .then(() => {
                info("Request to join team registered")
            })
            .then(() => setReady(true))
            .catch(handleError)
            .finally(stopWait)
    }, [fetchApi, handleError, setReady, waitFor]);

    React.useEffect(getTeam, [getTeam]);

    // If team is created but is not yet present (no link), then poll for team link creation
    useInterval(getTeam, Boolean(team) && !Boolean(team.link) ? 1000 : null);

    const notifyCreatedTeam = () => {
        if (Boolean(team) && Boolean(team.link) && Boolean(prevTeam) && !Boolean(prevTeam.link)) {
            info("The team for this course has now been created.");
        }
        if (Boolean(gone)) {
            info("Team Integration has been forgotten, you may request a new Team for this Course if you want a new one, otherwise you're done");
        }
        if (Boolean(prevTeam) && !Boolean(team)) {
            danger("The remaining number of Blackboard API calls is too low. For security reasons we cannot create your team at the moment. Please try again tomorrow or contact ServiceLine");
        }
    }
    React.useEffect(notifyCreatedTeam, [danger, info, prevTeam, team, gone]);

    const teamName = `${properties.courseId}: ${properties.courseName}`;
    const canCreateCourse = hasRole(Role.ADMIN) || hasRole(Role.INSTRUCTOR) || hasRole(Role.TEACHING_ASSISTANT);

    return <React.Fragment>
        <h1>Microsoft Teams Integration</h1>
        <NoticeArea />
        {canCreateCourse && ready && <Instructor onCreate={createTeam} onSync={syncTeam} onForget={forgetTeam} teamName={teamName} team={team} onJoin={ joinTeam } />}
        {!canCreateCourse && ready && <Student teamName={teamName} team={team} />}
        {!ready && <Spinner />}
        <hr />
    </React.Fragment >
}

export default React.memo(ViewContainer);
