import PropTypes from "prop-types";
import React from "react";
import { Button, Badge } from "reactstrap";
import { ArrowRepeat } from 'react-bootstrap-icons';
import { Waiting } from "../../Store";

const printDateTime = dateTime => (dateTime && new Date(dateTime).toLocaleString('en-GB', { timeZone: 'Europe/London' })) || "n/a";

const Instructor = (props) => {
  const { team, teamName, onCreate, onSync, onForget, onJoin } = props;

  const { waiting } = React.useContext(Waiting);

  return (
    <React.Fragment>
      <p className="text-justify">
        By selecting the following button, a Microsoft (MS) Class Team will be created with the same name
        as this Blackboard course. 
      </p>
      <h4>Who is added to the Team:</h4>
      <p className="text-justify">
        <strong>Blackboard Instructors</strong> will become <strong>Team Owners</strong>.
        When you initially create your Blackboard Class Team, all those listed as an ‘Instructor’ on the Blackboard course will be added to the Team as a ‘Team Owner’. This is a one-time sync at the point that the Team is created. Team Owners can manually add or remove other Team Owners at any point, and Team Owners are able to leave any Teams they have been added to.
      </p>
      <p className="text-justify">IMPORTANT: <u>It is best practise to review and remove any Team Owners (Blackboard Instructors) who do not need access to the Class Team.</u>
      </p>
      <p className="text-justify">
        <strong>Blackboard Students</strong> will become <strong>Team Members</strong>. Students enrolled on the Blackboard course will be added to the Class Team as ‘Team Members’. Team Members cannot leave the Team or be removed manually. The list of Team Members will sync with Blackboard enrolments twice a day. This will remove students who have left the course and add students who have recently joined. 
      </p>
      <p className="text-justify">
        Missing students? See <a
            href="https://elearn.soton.ac.uk/knowledge-base/bb-course-roles/"
            target="_blank"
            rel="noreferrer"
        >Blackboard – Manage course enrolment</a>.
      </p>
      <p className="text-justify">
              <strong>Other Blackboard users. </strong>Only those with a course role of ‘Instructor’ or ‘Student’ will be added automatically to your Class Team. Teaching Assistants, and other Blackboard staff course roles, are NOT added to the Team when it is created. Team Owners may manually add any other staff to the Team as a Team Owner.
      </p>
      <h4>How do I access and manage the Team:</h4>
      <p className="text-justify">
You can navigate to your newly created Team in a few different ways:
    <ul>
    <li>Open the Microsoft Teams app, or desktop site, and locate the Team with the matching module name.</li>
    <li>Go to Blackboard course for the module. Select ‘Tools’ from the course menu and then ‘Teams Integration’ to open the associated Team.</li>
    <li><a
            href="https://elearn.soton.ac.uk/knowledge-base/create-a-tool-link-in-your-blackboard-course-menu/"
            target="_blank"
            rel="noreferrer">Add a Teams “Tool link” to the Blackboard course menu.</a></li>
    </ul>
      </p>
          <h4>Can I add Teams recordings to Recorded Sessions on Blackboard/the associated Panopto module folder?</h4>
          <p className="text-justify">
              You can set your Class Team to export all meeting recordings to Panopto. This will allow you and the students to view the recordings in the ‘Recorded Sessions’ area of the associated Blackboard course.
              <br />Please see our <a
                  href="https://elearn.soton.ac.uk/knowledge-base/panopto-importfromteams/"
                  target="_blank"
                  rel="noreferrer">eLearn guide on how to automatically import Teams videos into a Blackboard course Recorded Sessions folder.</a>
          </p>
          <h4>Where can I find further support and guides?</h4>
          <p>
              Please see our <a
                  href="https://elearn.soton.ac.uk/article-categories/teams/"
                  target="_blank"
                  rel="noreferrer">eLearn Guides for more information on setting up and using a Class Team.</a>
          </p>
      <hr />
          {Boolean(team?.link) ? Boolean(team?.gone) ? (
              <React.Fragment>
                  <p>
                      The Class Team associated with this Blackboard course appears to have been <b>deleted</b>.<br />Last seen: <b>{printDateTime(team.exists)}</b>.
                  </p>
                  <p>
                      Select the <b>Forget Class Team</b> to remove the associated Class Team from the Blackboard course, or read our guide about{" "}
                      <a href="https://elearn.soton.ac.uk/knowledge-base/deleted-team/" target="_blank" rel="noreferrer">how to <b>recover a deleted Class Team</b></a>.
                  </p>
                  <p>
                      A new Class Team can be created after selecting the <b>Forget Class Team</b> button.
                  </p>
                  <p>
                      <Button
                          color="danger"
                          disabled={waiting}
                          onClick={onForget}
                      >
                          Forget Class Team
                      </Button>
                  </p>
        </React.Fragment>
         ) : (
        <React.Fragment>
          <p>
            The Team exists and has the name{" "}
            <Badge pill color="info">
              {teamName}
            </Badge>
          </p>
          <p>
            <Button
              color="default"
              href={team.link}
              disabled={waiting}
              target="_blank"
            >
              Go to MS Team
            </Button>{" "}
            <Button
              color="primary"
              disabled={waiting}
              onClick={onSync}
            >
              <ArrowRepeat fontSize="1.1rem"/> Sync Users
            </Button>{" "}
             <Button
             color="default"
             disabled={waiting}
             onClick={onJoin}>
             Join this team
             </Button>
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            Selecting the following button will create a Microsoft Team with the
            name{" "}
            <Badge pill color="info">
              {teamName}
            </Badge>
          </p>
          <p>
            <Button
              color="primary"
              onClick={onCreate}
              disabled={waiting || (Boolean(team) && !team.link)}
            >
              Create a MS Team for this course
            </Button>
          </p>
        </React.Fragment>
      )}
      {Boolean(team?.created) && !Boolean(team?.gone) && (
        <p>
          <small>
            <strong>Last synchronized at: {printDateTime(team.verified)}</strong>
            <br />
            Created at: {printDateTime(team.created)}
          </small>
        </p>
      )}
    </React.Fragment>
  );
};

Instructor.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onSync: PropTypes.func.isRequired,
  onForget: PropTypes.func.isRequired,
  teamName: PropTypes.string,
  team: PropTypes.shape({
    created: PropTypes.any,
    gone: PropTypes.any,
    link: PropTypes.any,
    verified: PropTypes.any
  }),
};

export default Instructor;
