import PropTypes from "prop-types";
import React from "react";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import urljoin from "url-join";
import { Spinner } from "reactstrap";
import NoticeArea from "../../NoticeArea";
import { IgnoredError } from "../ErrorHandler";
import { ErrorHandler } from "..";

/** 
 * @typedef AuthenticationProperties
 * @property {String} username the admin role
 * @property {String} courseId the instructor role
 * @property {String} courseName the learner role
 */
/**
 * @callback FetchApiFunction Fetch data from the secured API
 * @param {String} url the API url to request
 * @param {RequestInit} options fetch options {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_request_options}
 * @returns {Promise<Response>}
 */
/**
 * @callback HasRoleFunction Check if the current user has a specific role
 * @param {String} role the role name
 * @returns {Boolean}
 */
/** 
 * @typedef {Object} AuthenticationContext
 * @property {AuthenticationProperties} properties the properties relating to this session
 * @property {HasRoleFunction} hasRole check if the current user has a specific role
 * @property {FetchApiFunction} fetchApi fetch data from secured API
 */
/** @type {React.Context<AuthenticationContext>} */
const Context = React.createContext();

/**
 * Error thrown when there was problem authenticating the app
 *
 * @class AuthenticationError
 * @extends {HandledError}
 */
class AuthenticationError extends IgnoredError {
  /**
   * Creates an instance of NotLoggedInError.
   * @memberof AuthenticationError
   * @param message the error message
   */
  constructor(message) {
    super("AuthenticationError", message, null, null);
  }
}

/** 
 * @typedef AuthenticatedRole
 * @property {String} ADMIN the admin role
 * @property {String} INSTRUCTOR the instructor role
 * @property {String} TEACHING_ASSISTANT the teaching assistant role
 * @property {String} LEARNER the learner role
 */
/** 
 * The set of application roles 
 * @type {AuthenticatedRole}
 */
const Role = {
  ADMIN: "Administrator",
  INSTRUCTOR: "Instructor",
  TEACHING_ASSISTANT: "TeachingAssistant",
  LEARNER: "Learner"
}

/**
 * Provider for authentication context
 *
 * @param {Object} props
 * @return {*} 
 */
const Provider = props => {

  const [ready, setReady] = React.useState(false);

  /** @type {[String, React.Dispatch<String>]} */
  const [token, setToken] = React.useState(null);

  /** @type {[AuthenticationProperties, React.Dispatch<AuthenticationProperties>]} */
  const [properties, setProperties] = React.useState(null);

  /** @type {[Array<String>, React.Dispatch<Array<String>>]} */
  const [roles, setRoles] = React.useState(null);

  /** @type {[Array<String>, React.Dispatch<Array<String>>]} */
  const {handleError} = React.useContext(ErrorHandler);

  /** @type {FetchApiFunction} */
  const fetchApi = React.useCallback((url, options = {}) => {
    const fullUrl = urljoin("/api", url);
    if (!Boolean(options.headers)) {
      options.headers = new Headers();
    }
    options.headers.append("Authorization", `Bearer ${token}`);
    return fetch(fullUrl, options);
  }, [token]);

  /** @type {HasRoleFunction} */
  const hasRole = React.useCallback(role => roles?.includes(role), [roles]);

  const initialize = () => {
      const tempToken = Cookies.get("token");
      const { role, unique_name: username, course_id: courseId, course_name: courseName} = jwt.decode(tempToken + ".ignore-signature", {json: true}) || {};

      if (Boolean(tempToken) && Boolean(role)) {
        setRoles(Array.isArray(role) ? role : [role]);
        setToken(tempToken);
        setProperties({
          username,
          courseId,
          courseName
        })
        setReady(true);
      } else {
        handleError(new AuthenticationError("Unauthorized"));
      }

  };
  React.useEffect(initialize, [handleError]);

  return <Context.Provider value={{ properties, fetchApi, hasRole }}>
    {ready ? props.children : <React.Fragment>
      <NoticeArea />
      <div className="d-flex vh-100 justify-content-center align-items-center"><Spinner /></div>
    </React.Fragment>}
  </Context.Provider>
}

Provider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

export { Context, Role, AuthenticationError };
export default React.memo(Provider);
