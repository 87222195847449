import PropTypes from "prop-types";
import React from "react";
import { Button, Badge } from "reactstrap";
import { Waiting } from "../../Store";

const Student = (props) => {
  const { team, teamName } = props;

  const { waiting } = React.useContext(Waiting);

  return (
    <React.Fragment>
      <p className="text-justify">
        As a student on this course you can directly access your course's MS
        Teams area from this page.
      </p>
      <hr />
      {Boolean(team?.link) ? (
        <React.Fragment>
          <p>
            The Team exists and has the name{" "}
            <Badge pill color="info">
              {teamName}
            </Badge>
          </p>
          <p>
            <Button
              color="default"
              href={team.link}
              disabled={waiting}
              target="_blank"
            >
              Go to MS Team
            </Button>
          </p>
        </React.Fragment>
      ) : (
        <p>
          <strong>This course does not currently have a Team.</strong>
        </p>
      )}
    </React.Fragment>
  );
};

Student.propTypes = {
  teamName: PropTypes.string,
  team: PropTypes.shape({
    created: PropTypes.any,
    link: PropTypes.any,
    verified: PropTypes.any,
  }),
};

export default Student;
