import React from "react";
import PropTypes from "prop-types";
import AuthenticationProvider from "./Authentication";
import WaitingProvider from "./Waiting";
import NoticeProvider from "./Notice";
import ErrorHandlerProvider from "./ErrorHandler";

const Store = props =>
    <NoticeProvider>
        <ErrorHandlerProvider>
            <WaitingProvider>
                <AuthenticationProvider>
                    {props.children}
                </AuthenticationProvider>
            </WaitingProvider>
        </ErrorHandlerProvider>
    </NoticeProvider>

Store.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

export { Context as Authentication, Role } from "./Authentication";
export { Context as Waiting } from "./Waiting";
export { Context as Notice } from "./Notice";
export { Context as ErrorHandler } from "./ErrorHandler";
export default React.memo(Store);
