import React from "react";
import { Container } from "reactstrap";
import Content from "./components/Content";
import Footer from "./components/Footer";
import Store from "./components/Store";
import ViewContainer from "./components/ViewContainer";

const App = () => <Store>
  <Container>
    <Content>
      <ViewContainer/>
    </Content>
    <Footer />
  </Container>
</Store>

export default React.memo(App);