import PropTypes from "prop-types";
import React from "react";

/** 
 * @callback WaitForFunction Wait for the label
 * @param {String} label the label to wait for
 * @returns {StopWaitFunction} stop this wait
 */
/** 
 * @callback StopWaitFunction Stop waiting for the label
 * @param {String} label the label to stop waiting for. If null stop all waits
 * @returns {Void}
 */
/** 
 * @typedef {Object} WaitingContext
 * @property {WaitForFunction} waitFor wait for function
 * 
 * @property {DismissFunction} info dismiss alert
 * @property {Boolean} waiting true if app is waiting for something
 */
/** @type {React.Context<WaitingContext>} */
const Context = React.createContext(false);

/**
 * Waiting context provider
 *
 * @param {Object} props
 * @return {*} 
 */
const Provider = props => {

    const [waitingStack, setWaitingStack] = React.useState([]);

    /** @type {StopWaitFunction} */
    const stopWait = React.useCallback(label => setWaitingStack(Boolean(label) ? prev => prev.filter(l => l !== label) : []), []);
    
    /** @type {WaitForFunction} */
    const waitFor = React.useCallback(label => {
        setWaitingStack(prev => [...prev, label])
        return () => stopWait(label);
    }, [stopWait]);

    return <Context.Provider value={{waiting: waitingStack.length > 0, waitFor}}>
        {props.children}
    </Context.Provider>
}

Provider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

export { Context }
export default React.memo(Provider);
